var downIcon = 'icon-nd-arrow-down';
var rightIcon = 'icon-nd-arrow-right';

var NavigationBar = {};
NavigationBar.hideSidebar = function() {
    $("#wrap").addClass("toggled");
    Storage.setCookie('menuToggled', true);
}

NavigationBar.openSidebar = function() {
    $("#wrap").removeClass("toggled");
    $("#wrap").removeClass("closed");
    $("#sidebar-wrapper").show();
    Storage.setCookie('menuToggled', false);
}

NavigationBar.toggleList = function(id, close) {
    var submenuListItemHeight = 38;
    let submenuClass = '.' + id + '-list';
    let itemsCount = $(submenuClass).children().length;
    let idSelector = '#' + id;
    if (close) {
        $(submenuClass).height((itemsCount > 2 ? 2 : itemsCount)*submenuListItemHeight);
        $(idSelector).text('Show more');
        Storage.setCookie(id, false);
    } else {
        $(submenuClass).height(itemsCount*submenuListItemHeight);
        $(idSelector).text('Close');
        Storage.setCookie(id, true);
    }
};
NavigationBar.toggleSubmenu = function(id, close) {
    let submenuClass = '.' + id + '-submenu';
    let root = '.' + id + '-root';
    let idSelector = '#' + id;
    if (close) {
        $(submenuClass).addClass('hidden');
        $(root).removeClass('opened');
        $(root+' input').css('display','none');
        $(idSelector).attr('class', 'icon ' + rightIcon);
        Storage.setCookie(id, false);

    } else {
        if($(root).display !== "none"){
            $(root).addClass('opened');
            $(root+' input').css('display','block');
            $(submenuClass).removeClass('hidden');
            $(idSelector).attr('class', 'icon ' + downIcon);
            Storage.setCookie(id, true);
        }
    }
    //NavigationBar.maxHeight();
}
NavigationBar.updateMenu = function(id) {
    NavigationBar.toggleSubmenu(id, $('#' + id).hasClass(downIcon));
    NavigationBar.maxHeight();
};
NavigationBar.checkShowMore = function() {
    let showMoreButtons = ['show-my-projects', 'show-shared-projects'];
    showMoreButtons.forEach(function (item) {
        NavigationBar.toggleList(item, Storage.getCookie(item) !== 'true')
    })
};
NavigationBar.toggleShowMoreItem = function(id) {
    NavigationBar.toggleList(id, Storage.getCookie(id) === 'true');
};
NavigationBar.maxHeight = function() {
    let maxHeight = 5*45 + 90;
    //maxHeight += withoutSubmenu !== undefined ? 0 : 150;
    let forClose = [
        "nav-events",
        "nav-dossiers",
        "nav-providers",
        "nav-language",
        "nav-region",
        "nav-stakeholders",
        "nav-authors",
        "nav-socialAccounts",
        "nav-hashtags",
        "nav-bills",
        "nav-record_numbers",
        "nav-doc_types",
    ];

    let isOpened = false;
    let forNewSize = []
    let navHeight = $("#sidebar-wrapper").height();

    $.each(forClose, function (index, cl) {
        let ul = $('.myNewsDesk ul.news-desk-list-new');
        let item = $(ul[0]).find(".filter." + cl + "-root");
        if (item.length != 0 && !$(item[0]).hasClass('hidden')) {
            $(".submenu." + cl + "-submenu").css( "maxHeight", "");
            maxHeight += 45;
            forNewSize.push(cl);
            if ($(item[0]).hasClass('opened')) {
                isOpened = true;
            }
        }
    });

    if ((navHeight - maxHeight) > 300) {
        if (isOpened) {
            maxHeight += 150;
        }
    } else {
        let newHeight = navHeight - maxHeight;
        if (isOpened) {
            maxHeight += newHeight/2;
        }
    }

    maxHeight = navHeight-maxHeight > 300 ? navHeight-275 : maxHeight;
    let mh = 'calc(100vh - ' + maxHeight + 'px)';
    $(".submenu.nav-desks-submenu").css( "maxHeight", mh);
    $(".submenu.nav-stakeholders-panel-submenu").css( "maxHeight", mh);



    NavigationBar.calculateFiltersHeight(forNewSize);
};


NavigationBar.calculateFiltersHeight = function(forNewSize){

    var newsDesksSubMenu = $(".submenu.nav-desks-submenu");
    var newsDesksSubMenuHeight =  0;
    var stakeholdersSubMenu = $(".submenu.nav-stakeholders-panel-submenu");
    var stakeholdersSubMenuHeight =  0;

    if(!newsDesksSubMenu.hasClass('hidden'))
        newsDesksSubMenuHeight = newsDesksSubMenu.height();

    if(!stakeholdersSubMenu.hasClass('hidden'))
        stakeholdersSubMenuHeight = stakeholdersSubMenu.height();


    $.each(forNewSize, function (indexNewSize, submenu) {
        //this method only retrieve the calculation on myNewsDesks div, currently is working with flexbox, but still working.
        var  myNewsDesksHeight = NavigationBar.getMyNewsDesksHeight($(window).height());

        var  submenuHeight = NavigationBar.getSubmenuHeight(myNewsDesksHeight);

        var inputSiblingHeight = 0;

        //parseInt(window.getComputedStyle($(".submenu.nav-providers-submenu")[0].previousElementSibling).marginBottom)
        if($(".submenu." + submenu + "-submenu")[0].previousElementSibling.tagName == 'INPUT')
            inputSiblingHeight = parseInt( window.getComputedStyle( $(".submenu." + submenu + "-submenu")[0].previousElementSibling ).marginBottom ) + $(".submenu." + submenu + "-submenu")[0].previousElementSibling.clientHeight;
        // console.log("submenuHeight",submenuHeight);

        // console.log('submenu'+submenu, inputSiblingHeight);

        $(".submenu." + submenu + "-submenu").css( "max-height", ( submenuHeight - newsDesksSubMenuHeight - stakeholdersSubMenuHeight - inputSiblingHeight )+'px');
    });

}

NavigationBar.getElements = function(){
    var elements = $(".myNewsDesk > ul > div:not(.hidden) .submenu");

    return elements;
    console.log('elements', elements);
}


NavigationBar.checkSubmenus = function() {
    let subMenus = ['nav-desks', 'nav-dossiers', 'nav-stakeholders-panel', 'nav-projects', 'nav-archives', 'nav-providers',
        'nav-stakeholders', 'nav-authors', 'nav-socialAccounts', 'nav-hashtags', 'nav-bills','nav-record_numbers','nav-doc_types', 'nav-events'];
    subMenus.forEach(function (item) {
        if(Storage.getCookie(item) === 'true'){
            NavigationBar.updateMenu(item)
        }
    })
};
NavigationBar.showAndHideBtnAdd = function(showAndHideBtnAddParameters) {
    var arrowsMyNewsDesk = showAndHideBtnAddParameters.arrowsMyNewsDesk;
    var arrowsBottomNav = showAndHideBtnAddParameters.arrowsBottomNav;
    var triggerClass = showAndHideBtnAddParameters.triggerClass;

    if( $(arrowsMyNewsDesk).hasClass(triggerClass) == true ){
        $('.myNewsDesk .btn_add').attr('style', 'display: inline !important');
    }else{
        $('.myNewsDesk .btn_add').attr('style', 'display: none !important');
    }

    if( $(arrowsBottomNav).hasClass(triggerClass) == true ){
        $('.bottom-nav .btn_add').attr('style', 'display: inline !important');
    }else{
        $('.bottom-nav .btn_add').attr('style', 'display: none !important');
    }
}
NavigationBar.init = function() {
    if(window.location.pathname.includes('admin')){
        $("#wrap").addClass("closed");
        Storage.setCookie('menuToggled', true);
        return;
    }
    NavigationBar.checkSubmenus();

    //NavigationBar.checkShowMore();
    var menuToggled = Storage.getCookie('menuToggled');
    if(menuToggled === null && $(window).width() > 1200) Storage.setCookie('menuToggled', false);
    var page = window.location.pathname;
    if(page.includes('search')){
        NavigationBar.toggleSubmenu('nav-stakeholders-panel', true);
        NavigationBar.toggleSubmenu('nav-desks', true);
    } else if(page.includes('stakeholder')){
        NavigationBar.toggleSubmenu('nav-stakeholders-panel', false);
        NavigationBar.toggleSubmenu('nav-desks', true);
    } else if(page.includes('report')){
        NavigationBar.toggleSubmenu('nav-stakeholders-panel', true);
        NavigationBar.toggleSubmenu('nav-desks', true);
        Storage.setCookie('menuToggled', true)
    } else {
        NavigationBar.toggleSubmenu('nav-stakeholders-panel', true);
        NavigationBar.toggleSubmenu('nav-desks', false);
    }


    if(menuToggled === "true") {
        if ($(window).width() < 768) {
            $("#wrap").removeClass("toggled");
            $("#wrap").addClass("closed");
        } else{
            $("#wrap").removeClass("closed");
            $("#wrap").addClass("toggled");
            $("#sidebar-wrapper").show();
        }
    } else {
        $("#wrap").removeClass("toggled");
        $("#wrap").removeClass("closed");
        $("#sidebar-wrapper").show();
    }

    if ($(window).width() < 768){
        // open stakeholder search filter on non-small screens and hide toggle button
        $('#stake-filters').addClass('collapse');
    }

    $(".nav-element.submenu-item.active").each(function (index) {
        let offset = 0;
        let stop = false;
        let parent = $(this).parent();
        parent.children('.nav-element.submenu-item').each(function () {
            if($(this).hasClass('active')){
                stop = true;
            }
            if(!stop){
                offset++;
            }
        });
        //Extend offset for recommended projects
        if ( parent.hasClass("show-shared-projects-list")){
            //because of Recommended label
            offset++;
            stop = false;
            $(".show-my-projects-list").children('.nav-element.submenu-item').each(function () {
                if($(this).hasClass('active')){
                    stop = true;
                }
                if(!stop){
                    offset++;
                }
            });
        }
        $(this).closest('.submenu').animate({ scrollTop: offset*30 }, { duration: 'medium', easing: 'swing' });
    });

    //NavigationBar.maxHeight();
};


NavigationBar.getMyNewsDesksHeight = function (windowHeight){
    //y = 850.1163 + (317.0138 - 850.1163)/(1 + (x/834.8971)^6.49416)
    var power = Math.pow(windowHeight/834.8971,6.49416);

    return Math.round(850.1163 + (317.0138 - 850.1163)/(1 + power));
}


NavigationBar.getSubmenuHeight = function (myNewsDesksHeight){
    //y = 1531058 + (-368.6855 - 1531058)/(1 + (x/16044160)^0.7422216)
    var power = Math.pow(myNewsDesksHeight/16044160,0.7422216);

    return Math.round(1531058 + (-368.6855 - 1531058)/(1 + power));
}


NavigationBar.hideNavigatorList = function () {
    $(".submenu.nav-projects-submenu").addClass('hidden');
    $('#nav-projects').attr('class', 'icon ' + rightIcon);
}

$(window).resize(function(){
    NavigationBar.maxHeight();
});