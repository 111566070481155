$(window).ready(function(){

    //dirty solution of custom pagination for nayjest table
    $(".oldChangePage").click(function(){
        let list = $(this).data('list');

        let hiddenPage = document.createElement('input');
        $(hiddenPage).attr('type',  'hidden');
        $(hiddenPage).attr('name', list +'[page]');
        $(hiddenPage).attr('value', $(this).data('page'));
        let hiddenPerPage = document.createElement('input');
        $(hiddenPerPage).attr('type', 'hidden');
        $(hiddenPerPage).attr('name', list + '[filters][records_per_page]');
        $(hiddenPerPage).attr('value', $("#resultsShown").val());//TO DO

        let form = $('#' + $(this).data('container')).find('form');
        form.append(hiddenPage);
        form.append(hiddenPerPage);
        var dossier_type = urlParam('dossier_type');
        if (dossier_type) {
            let hiddenDossierType = document.createElement('input');
            $(hiddenDossierType).attr('type',  'hidden');
            $(hiddenDossierType).attr('name', 'dossier_type');
            $(hiddenDossierType).attr('value', dossier_type);
            form.append(hiddenDossierType);
        }
        var dossier_providers = urlArrayParam('dossier_provider[]');
        if (dossier_providers) {
            if (Array.isArray(dossier_providers)) {
                for (let i = 0; i < dossier_providers.length; i++) {
                    let hiddenDossierProvider = document.createElement('input');
                    $(hiddenDossierProvider).attr('type', 'hidden');
                    $(hiddenDossierProvider).attr('name', 'dossier_provider[]');
                    $(hiddenDossierProvider).attr('value', dossier_providers[i]);
                    form.append(hiddenDossierProvider);
                }
            } else {
                let hiddenDossierProvider = document.createElement('input');
                $(hiddenDossierProvider).attr('type', 'hidden');
                $(hiddenDossierProvider).attr('name', 'dossier_provider[]');
                $(hiddenDossierProvider).attr('value', dossier_providers);
                form.append(hiddenDossierProvider);
            }
        }
        form.submit();
    });
    //dirty solution of custom pagination for nayjest table
    $(".oldChangeResults").change(function(){
        let list = $(this).data('list');

        let hiddenPage = document.createElement('input');
        $(hiddenPage).attr('type',  'hidden');
        $(hiddenPage).attr('name', list +'[page]');
        $(hiddenPage).attr('value', 1);
        let hiddenPerPage = document.createElement('input');
        $(hiddenPerPage).attr('type', 'hidden');
        $(hiddenPerPage).attr('name', list + '[filters][records_per_page]');
        $(hiddenPerPage).attr('value', $("#resultsShown").val());//TO DO

        let form = $('#' + $(this).data('container')).find('form');
        form.append(hiddenPage);
        form.append(hiddenPerPage);
        form.submit();
    });


})
