var FilterRules = {};
FilterRules.checkVisibility = function() {
    var providerGroup = $("#provider_group_container").val();
    //'all' 'administration' 'forum' 'courts' 'legislative' news social_media
    FilterRules.checkItem(['all', 'legislative'], '.nav-dossiers-root');
    FilterRules.checkItem(['social_media'], '.nav-socialAccounts-root');
    FilterRules.checkItem(['social_media'], '.nav-hashtags-root');

    if (providerGroup == 'legislative') {
        $("#parliamentButtonWrapper").removeClass('hidden');
        //$('.nav-bills-root, .nav-record_numbers-root, .nav-authors-root, .nav-providers-root').removeClass('hidden');
        $('.nav-bills-root, .nav-record_numbers-root, .nav-providers-root, .nav-doc_types-root').removeClass('hidden');
    } else if (providerGroup == 'news') {
        $('.nav-record_numbers-root').addClass('hidden');
        $("#parliamentButtonWrapper").addClass('hidden');
        $('.nav-bills-root, .nav-authors-root, .nav-providers-root, .nav-doc_types-root').removeClass('hidden');
    } else if (providerGroup == 'all' || providerGroup == 'administration' || providerGroup == 'forum' || providerGroup == 'courts') {
        $('.nav-record_numbers-root').addClass('hidden');
        $("#parliamentButtonWrapper").addClass('hidden');
        $('.nav-providers-root, .nav-doc_types-root').removeClass('hidden');
    } else {
        $("#parliamentButtonWrapper").addClass('hidden');
        //$('.nav-bills-root, .nav-record_numbers-root, .nav-authors-root').removeClass('hidden');
        $('.nav-bills-root, .nav-record_numbers-root').removeClass('hidden');
    }
    $('.nav-stakeholders-root').removeClass('hidden');

    FilterRules.hideEmpty();
}

FilterRules.checkItem = function(array, item) {
    var providerGroup = $("#provider_group_container").val();
    array.includes(providerGroup)
        ? $(item).removeClass('hidden')
        : $(item).addClass('hidden');
};

FilterRules.hideEmpty = function() {
    let items = [
        "nav-dossiers",
        "nav-providers",
        "nav-stakeholders",
        "nav-authors",
        "nav-socialAccounts",
        "nav-hashtags",
        "nav-bills",
        "nav-record_numbers",
        "nav-doc_types"
    ];
    $.each(items, function( index, item ) {
        if ($( '.' + item + '-submenu > li').children().length < 1) {
            $('.' + item + '-root').addClass('hidden');
        }
    });
    NavigationBar.maxHeight();
};
