$(window).ready(function(){

    NavigationBar.init();
    if(window.location.pathname == '/auth/account'){
        NavigationBar.toggleSubmenu('nav-desks', true);
    }

    $("#menu-open, .menu-open").click(function(e) {
        e.preventDefault();

        //$("#sidebar-wrapper").fadeIn(800);
        $(".left-navigation-content").attr('opacity', 0);
        $("#wrap").removeClass("toggled");
        $("#wrap").removeClass("closed");
        $("#sidebar-wrapper").show();
        $(".left-navigation-content").attr('opacity', 1);
        $(".left-navigation-content").show();
        Storage.setCookie('menuToggled', false);
    });

    $("#menu-close, .menu-close").click(function(e) {
        e.preventDefault();
        if ($(window).width() < 768) {
            $('.left-navigation-content').hide();
            $("#wrap").removeClass("toggled");
            $("#wrap").addClass("closed");
            /*$("#sidebar-wrapper").attr('opacity', 0);*/
            //$("#sidebar-wrapper").fadeOut(800);
            $("#sidebar-wrapper").hide();
            Storage.setCookie('menuToggled', true);
        } else {
            $("#wrap").removeClass("closed");
            $("#wrap").addClass("toggled");
        }
    });

    $(".filter li").click(function() {
        let forClose = [
            "nav-events",
            "nav-dossiers",
            "nav-providers",
            "nav-language",
            "nav-region",
            "nav-stakeholders",
            "nav-authors",
            "nav-socialAccounts",
            "nav-hashtags",
            "nav-bills",
            "nav-record_numbers",
            "nav-doc_types",
            "nav-projects",
            "nav-archives"
        ];
        let classes = $(this)[0].parentNode.classList;
        let rootClass = '';
        $.each(classes, function (index, cl) {
            cl.includes('root') ? rootClass = cl : '';
        });
        $.each(forClose, function (index, item) {
            if (item.includes(rootClass.replace('-root', ''))) {
                NavigationBar.updateMenu(item);
            } else {
                NavigationBar.toggleSubmenu(item, true);
            }
        });


        NavigationBar.maxHeight();
    });

    $("#nav-stakeholders-panel").click(function(){
        let forClose = [
            "nav-desks",
            "nav-events",
            "nav-dossiers",
            "nav-providers",
            "nav-language",
            "nav-region",
            "nav-stakeholders",
            "nav-authors",
            "nav-socialAccounts",
            "nav-hashtags",
            "nav-bills",
            "nav-record_numbers",
            "nav-doc_types",
            "nav-authors",
            "nav-projects",
            "nav-archives"
        ];
        $.each(forClose, function (index, item) {
            NavigationBar.toggleSubmenu(item, true);
        });
        NavigationBar.maxHeight();
    });

    $("#nav-desks, .new-logo").click(function(){
        NavigationBar.toggleSubmenu('nav-stakeholders-panel', true);
        NavigationBar.maxHeight();
    });

    $("#nav-desks, #nav-stakeholders-panel, #nav-projects, #nav-archives").click(function(){
        NavigationBar.updateMenu($(this).attr('id'));
    });

    $(".narrow-item.active").click(function(){
        NavigationBar.openSidebar();
    });

    var showAndHideBtnAddParameters = {
            'arrowsMyNewsDesk' : '.myNewsDesk #nav-desks, .myNewsDesk #nav-stakeholders-panel',
            'arrowsBottomNav' : '.bottom-nav #nav-stakeholders-panel, .bottom-nav #nav-desks',
            'triggerClass': 'icon-nd-arrow-down'

    };

    $(".myNewsDesk #nav-desks, .myNewsDesk #nav-stakeholders-panel").click(function(){
        NavigationBar.showAndHideBtnAdd(showAndHideBtnAddParameters);
    });

    $(".bottom-nav #nav-stakeholders-panel, .bottom-nav #nav-desks").click(function(){
        NavigationBar.showAndHideBtnAdd(showAndHideBtnAddParameters);
    });

    NavigationBar.showAndHideBtnAdd(showAndHideBtnAddParameters);

    NavigationBar.hideNavigatorList();

})