var Helper = {};
Helper.clearQuotes = function(string) {
    string = string.replace(/&quot;/g, '\"');
    string = string.replace(/&#039;/g, '\'');
    return string;
};

Helper.clearAmps = function(string) {
    string = string.replace(/&amp;/g, '&');
    return string;
};

Helper.prepareArray = function(string) {
    try {
        return JSON.parse(Helper.clearAmps(Helper.clearQuotes(string)));
    } catch (e) {
        console.log('CRITICAL ERROR: Invalid JSON, fix SOLR!')
        console.log(Helper.clearAmps(Helper.clearQuotes(string)))
        return [];
    }
};

Helper.toSnakeCase = function(string) {
    string = string.replace(' ', '_');
    return string.split(/(?=[A-Z])/).join('_').toLowerCase();
};

Helper.createLoader = function(url) {
    let loaderRow = document.createElement('div');
    $(loaderRow).addClass('row');
    let loaderCol = document.createElement('div');
    $(loaderCol).addClass('col-lg-12 text-center');
    let loader = document.createElement('img');
    $(loader).attr('src', url !== undefined ? url : window.loader_url);
    $(loader).attr('alt', 'Loading');
    $(loader).addClass('ajax-loader');
    $(loaderCol).append(loader);
    $(loaderRow).append(loaderCol);
    
    return loaderRow;
};

Helper.replaceWithLoader = function(selector) {
    let container = $(selector);
    $(container).html('');
    let loaderRow = Helper.createLoader();
    $(container).append(loaderRow);
};

Helper.calculateRangeIndex = function(sum, parts, x) {
    let part = sum / parts;
    for(let i = 1; i <= parts; i++) {
        if(x <= part * i) {
            return parts - i;
        }
    }
    return parts;
};

Helper.createRangeNames = function(steps, leap, max, percentage) {
    let seriesNames = [];
    if (percentage === undefined) {
        for (let i = 0; i < steps; i++) {
            seriesNames[steps-i-1] = i > 0 ?
                ( (i-1)*leap+1 + " - "+ ( i < steps-1 ? i*leap : max) )
                : i*leap;
        }
        return seriesNames;
    }
    let step = Math.round(100/(steps-1));
    for (let i = 0; i < steps; i++) {
        seriesNames[steps-i-1] = i > 0 ?
            //( (i-1)*step + "% - "+ ( i < steps-1 ? (i*step + "%") : "100%") )
            (" < "+ ( i < steps-1 ? (i*step + "%") : "100%") )
            : i + "%";
    }
    return seriesNames;
    

};

Helper.randomString = function(length) {
    const characters ='ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let result = ' ';
    const charactersLength = characters.length;
    for ( let i = 0; i < length; i++ ) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
};

Helper.cleanString = function(string) {
    if (string === undefined) {
        return '';
    }
    string = string.replace(/&quot;/g, "");
    string = string.replace('[', "");
    string = string.replace(']', "");
    return string;
};

Helper.createHashtagButtons = function(selector, hashtags, limit, selected) {
    if (selected === "" && $(selector).children().length > 0) {
        return;
    }
    hashtags = Helper.cleanString(hashtags);
    selected = Helper.cleanString(selected);
    let list = hashtags.split(',');
    $(selector).empty();
    list.forEach(function (item, index){
        if (index < limit) {
            let li = document.createElement('li');
            let span = document.createElement('span');
            $(li).addClass('hashtag-btn pull-left mr-10 hashtags no-shadow');
            if (selected === item) {
                $(li).addClass('active');
            }
            $(span).text(item);
            $(li).append(span);
            $(selector).append(li);
        }
    });
};

Helper.makeDiv = function(classes) {
    let div = document.createElement('div');
    $(div).addClass(classes);
    return div
};

Helper.showFacetLoader = function (selector) {
    let container = $(selector);
    $(container).html('');
    $(container).append('<div class="dot-loader-container"><div class="dot-falling"></div></div>');
}
