var NavigationBarSubmenu = {};
NavigationBarSubmenu.createItem = function(name, item, key, selectedItems) {
    let snakeItem = Helper.toSnakeCase(item);
    let li = document.createElement('li');
    let label = document.createElement('label');
    let input = document.createElement('input');
    let div = document.createElement('div');
    let a = document.createElement('a');
    let span = document.createElement('span');
    $(li).addClass('submenu-item nav-' + name + '-submenu');
    $(label).addClass('control control-checkbox no-wrap-text');
    let parts = item.split(" ");
    let text = item;
    let numberText = "";
    var cutText = "";
    if(parts.length > 1){
        let numbers = parts[parts.length - 1];
        numberText = numbers.length > 7 ?  (numbers.substring(0,7) + '...)') : numbers;
        text = item.replace(numbers, '').trim();
        cutText = text.length > 18 ? text.substring(0,18) +'...' : text;
    }

    $(label).text(cutText);
    $(span).addClass('pull-right mr-5');
    $(span).text(numberText);
    $(label).append(span);

    $(input).addClass(name + '-filter');
    $(input).attr('value', key);
    $(input).attr('type', 'checkbox');
    if(selectedItems && selectedItems !== {} && selectedItems.includes(key)){
        $(input).attr('checked', 'checked');
    }
    $(input).attr('name', name + '[]');
    $(input).attr('data-value', key);
    $(input).attr('id', name + '_' + snakeItem);
    $(input).attr('data-target', name);
    $(label).append(input);

    $(div).addClass('control_indicator');
    $(a).attr('href', '#' + name);
    $(a).attr('title', '#' + item);
    $(a).addClass('facet');
    $(a).attr('data-target', name + '_' + snakeItem);

    $(div).append(a);
    $(label).append(div);
    $(li).append(label);
    $(li).attr('title', text);

    return li;
}

NavigationBarSubmenu.create = function(name, serverItems, serverSelectedItems, includeAll) {
    let all = includeAll ? {'all' :'All'} : {};
    let items = $.extend(all, Helper.prepareArray(serverItems));
    let selectedItems = serverSelectedItems != null?Helper.prepareArray(serverSelectedItems):[];

    $('#left-menu-' + name).empty();

    let count = 0;
    $.each(items, function( index, value ) {
        count++;
        if(value.length < 2){
            return;
        } else if(count > 20){
            return;
        }
        let li = NavigationBarSubmenu.createItem(name, value, index, selectedItems);
        $('#left-menu-' + name).append(li);
    });

    let limit = includeAll ? 2 : 1;
    if(count >= limit) {
        NavigationBar.toggleSubmenu('nav-' + name, Storage.getCookie('nav-' + name) !== 'true');
    }
}

NavigationBarSubmenu.searchItems = function(inputElement, filter){
    let filterItemsContainer = $('#left-menu-'+filter);
    let filterItems = $('#left-menu-'+filter+' li');
    let inputValue = $(inputElement).val().toLowerCase();


    if(inputValue != '') {

        $.each(filterItems, function (index, item) {

            if (item.attributes.title.nodeValue.toLowerCase().indexOf(inputValue) == -1) {
                this.style.display = 'none';
            }else{
                this.style.display = 'block';
            }

            // console.log(item.textContent);
        });

    }else{
        console.log('INPUT VALUE IS EMPTY');
        console.log(inputValue);
        $.each(filterItems, function (index, item) {

            this.style.display = 'block';

        });
    }




}