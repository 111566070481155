$('#reportrange_search').on('apply.daterangepicker', function(ev, picker) {

    ev.preventDefault();

	documents = $("#documents_with_facets");
	$(".hidden_page").val($(this).data('page'));
	var filters = {};
	$(document).find("[data-search]").each(function () {
	    filters[$(this).data('search')] = $(this);
	});
	filters['page'] = $(".hidden_page");
	filters['date_start'] = window.date_start;
	filters['date_end'] = window.date_end;
	// console.log('Filters in main(15)', filters);
	getDocuments(documents, filters, false);
});




$(document).on('click', ".user-notification button.close", function(ev) {
    $.ajax({
        url: '/notifications/hide',
        type: 'POST',
        dataType: 'json',
        data: {
            _token: $("input[name='_token']").val(),
            'id' : $(this).data('id')
        },
        cache: false,
    }).error(function (data) {
        $.notify({
            // options
            message: data.responseText
        }, {
            // settings
            type: 'danger'
        });
    });
});

$(document).on('click', ".main-doc-cont-refresh", function(ev) {
    query = (this).find('#search_field').val();

   if (isNonAdminEmptySearch(query)){
        alert('Please enter a search query');
        return false;
    }

    documents = $("#documents_with_facets");
    $(".hidden_page").val($(this).data('page'));
    var filters = {};
    $(document).find("[data-search]").each(function () {
        filters[$(this).data('search')] = $(this);
    });
    filters['page'] = $(".hidden_page");
    filters['date_start'] = window.date_start;
    filters['date_end'] = window.date_end;
    $("input[name='q']").last().val(query);
    getDocuments(documents, filters, false);
    return false;
});

$(document).ready(function () {
	function getUrlParameter(sParam) {
	    var sPageURL = decodeURIComponent(window.location.search.substring(1)),
	        sURLVariables = sPageURL.split('&'),
	        sParameterName,
	        i;

	    for (i = 0; i < sURLVariables.length; i++) {
	        sParameterName = sURLVariables[i].split('=');

	        if (sParameterName[0] === sParam) {
	            return sParameterName[1] === undefined ? true : sParameterName[1];
	        }
	    }
	}

	function cb_search(start, end) {

		if (start == 'Thu Jan 01 1970 00:00:00 GMT+0100' || start.format('YYYY') == 1970) {
            $('#reportrange_search').addClass('auto-min-width');
            $('#reportrange_search span').html(window.trans('new_search.dates.entire_time','Entire Time'));
			window.date_start = 'Thu Jan 01 1970 00:00:00 GMT+0100';
            window.date_start_new = '1970-01-01';
        } else {
            $('#reportrange_search').removeClass('auto-min-width');
            $('#reportrange_search span').html(start.format('DD.MM.YYYY') + ' - ' + end.format('DD.MM.YYYY'));
			window.date_start = start.format('MMMM D, YYYY');
			window.date_start_new = start.format('YYYY-MM-DD');
		}

		window.date_end = end.format('MMMM D, YYYY');
        window.date_end_new = end.format('YYYY-MM-DD');
	}

	if ((!window.date_start || !window.date_end)){
		window.date_start = moment().subtract(29, 'days');
		window.date_end = moment();
	}

	dateRangeLabels = {
        'entire_time': [window.trans('new_search.dates.entire_time','Entire Time'),['01/01/1970', moment().add(30, 'days')]],
        'today': [window.trans('new_search.dates.today','Today'),[moment(), moment()]],
        'yesterday': [window.trans('new_search.dates.yesterday','Yesterday'),[moment().subtract(1, 'days'), moment().subtract(1, 'days')]],
        'last_week': [window.trans('new_search.dates.last_week','Last 7 Days'),[moment().subtract(6, 'days'), moment()]],
        'last_thirty': [window.trans('new_search.dates.last_thirty','Last 30 Days'),[moment().subtract(29, 'days'), moment()]],
        'this_month': [window.trans('new_search.dates.this_month','This Month'),[moment().startOf('month'), moment().endOf('month')]],
        'this_year': [window.trans('new_search.dates.this_year','This Year'),[moment().startOf('year'), moment().endOf('year')]],
    };

	window.dateRanges = {};

	for (label in dateRangeLabels) {
	    var range = dateRangeLabels[label];
	    window.dateRanges[range[0]] = range[1];
    }

    // console.log(window.date_end);
	$('#reportrange_search').daterangepicker({
        startDate: window.date_start,
        endDate: window.date_end,
        showDropdowns: true,
        linkedCalendars:false,
        autoUpdateInput: true,
        minYear:1970,
	    ranges: window.dateRanges,
        format: 'mm/dd/yyyy',
        locale: {
            "cancelLabel": window.trans('new_search.dates.cancel_btn','Cancel'),
            "applyLabel": window.trans('new_search.dates.apply_btn','Apply'),
            "customRangeLabel": window.trans('new_search.dates.custom_range','Custom Range'),
        }
	}, cb_search);

    cb_search(window.date_start, window.date_end);






	$("#reportrange_search").prependTo('#period-filter-box');

    $(".location-icon").tooltip({
        placement: "right"
    });

    $(".bill").readmore({
        //collapsedHeight: 35,
        collapsedHeight: 25,
        moreLink: '<div class="pull-right"><a href="#">See more</a></div>'
    });
    $(".author_list").readmore({
        collapsedHeight: 18,
        moreLink: '<div class="pull-right"><a href="#">See more</a></div>'
    });

    generateAlertPopover();
    generateInfoBox();

    $(document).on("click", ".time-period-change", function (event) {
        $('#reportrange_search').click();
    });

    $(document).on("click", ".addCheckpoint", function (event) {
        var btn = $(this);
        $.ajax({
            url: $(this).data('url'),
            type: 'POST',
            data: {
                _token: $("input[name='_token']").val(),
                doc_id: $(btn).data('doc_id'),
                dashboard_id: $(btn).data('dashboard_id'),
            },
            dataType: 'json'
        }).done(function (data) {
            console.log(data);
            $.notify({
                // options
                message: data.message
            }, {
                // settings
                type: data.type
            });
            if (data.status == true) {

                $(btn).closest('.document').find('.checkpoint').css("display", "block");
            } else {
                $(btn).closest('.document').find('.checkpoint').css("display", "none");
            }

        }).error(function (response) {
            $.notify({message: response.responseJSON.message}, {type: response.responseJSON.type});
        });
    });


    $(document).on("click", ".addToBookmark", function (event) {
        var btn = $(this);
        event.stopPropagation();
        event.preventDefault();
        $.ajax({
            url: $(this).data('url'),
            type: 'POST',
            data: {
	            q: $("input[name='q']").last().val(),
	            _token: $("input[name='_token']").val(),
				snippet: $('.document_fulltext_'+$(this).data('docid')).html()
            },
            dataType: 'json'
        }).done(function (data) {

            $.notify({
                // options
                message: data.message
            }, {
                // settings
                type: data.type
            });
            if (data.status == true) {
                btn.html("<i></i>Saved");
                btn.find("i").addClass('glyphicon glyphicon-ok');
                btn.closest(".btn-group").removeClass('show-on-hover');
            } else {
                if(btn.data('reportplanner')){
                    btn.html("Save");
                }
                else{
                    btn.html("bookmarks");
                }
                btn.removeClass("no-border");
                btn.find("i").addClass('glyphicon glyphicon-plus');
                if ($('a.bookmarks-view').hasClass('active')) {
                    btn.closest(".document").parent(".row").remove();
                }
                btn.closest(".btn-group").addClass('show-on-hover');
            }

            btn.closest(".btn-group").find('.document-actions-toggle').toggleClass('text-success');
            btn.closest(".btn-group").find('.document-actions-toggle').toggleClass('d-inline');
            btn.closest(".btn-group").find('.document-actions-toggle').toggleClass('show-on-hover');

        }).error(function (response) {
            $.notify({message: response.responseJSON.message}, {type: response.responseJSON.type});
        });

    });
    $(".addToBookmark").hover(function () {
        var btn = $(this);
        btn.css("text-decoration", "underline");
        if (btn.find('i').hasClass("glyphicon-ok")) {
            btn.html('<i></i>Remove');
            btn.find('i').addClass("glyphicon glyphicon-remove");
        }
    }, function (event) {
        var btn = $(this);
        btn.css("text-decoration", "none");
        if (btn.find('i').hasClass("glyphicon-remove")) {
            btn.html('<i></i>Saved');
            btn.find('i').addClass("glyphicon glyphicon-ok");
        }
    });

    $('.change_color').each(function () {
        $(this).minicolors({
            control: 'hue',
            defaultValue: '',
            format: 'hex',
            keywords: '',
            inline: $(this).attr('data-inline') === 'true',
            letterCase: 'lowercase',
            opacity: false,
            position: 'bottom left',
            hide: function () {
                $.ajax({
                    url: $(this).data('url'),
                    data: {
                        id: $(this).data('id'),
                        color: $(this).val()
                    },
                    type: 'GET',
                    dataType: 'json'
                }).done(function (data) {
                    $.notify({
                        // options
                        message: data.message
                    }, {
                        // settings
                        type: data.type
                    });
                });
            },
            theme: 'bootstrap'
        });

    });

    // Fix for multiple modal double scrollbar bug
    $(document).on('hidden.bs.modal', '.modal', function () {
        $('.modal:visible').length && $(document.body).addClass('modal-open');
    });

    // Bootstrap tooltip
    $('.tooltip_field').tooltip();

    // Select2
    $(".select2-tag").select2({
        tags: true,
        tokenSeparators: [',']
    });

    // Datatables
    $(".datetable").DataTable({
        "order": [[0, "desc"]]
    });

    $('.dropdown.mega-dropdown a').on('click', function (event) {
        $(this).parent().toggleClass('open');
        $(window).resize();
    });

    $('body').on('click', function (e) {
        if (!$('li.dropdown.mega-dropdown').is(e.target)
            && $('li.dropdown.mega-dropdown').has(e.target).length === 0
            && $('.open').has(e.target).length === 0
        ) {
            $('li.dropdown.mega-dropdown').removeClass('open');
        }
    });

    $(document).on('change', '.highlighter', function () {
        if ($(this).find(":selected").val() == "off") {
            $(".document").find(".document_fulltext").addClass("highlight_off");
        } else if ($(this).find(":selected").val() == "on") {
            $(".document").find(".document_fulltext").removeClass("highlight_off");
        }
    });

		$(document).on({
		    mouseenter: function () {
					var btn = $(this);
					btn.css("text-decoration", "underline");
					if (btn.find('i').hasClass("glyphicon-ok")) {
							btn.html('<i></i>Remove');
							btn.find('i').addClass("glyphicon glyphicon-remove");
					}
		    },
		    mouseleave: function () {
					var btn = $(this);
	        btn.css("text-decoration", "none");
	        if (btn.find('i').hasClass("glyphicon-remove")) {
	            btn.html('<i></i>Saved');
	            btn.find('i').addClass("glyphicon glyphicon-ok");
	        }
				}
		}, ".addToBookmark");



    $(document).on('click', '#close-agenda', function (e) {
        e.preventDefault();
        $('#eventToggle').removeClass('active');
        $('#eventToggle').prop('disabled', true);
        $('#agenda').fadeToggle(function() {
            $('#eventToggle').prop('disabled', false);
        });
    });
    $(document).on('click', '#eventToggle', function (e) {
        e.preventDefault();
        if($('#eventToggle').hasClass('active')){
            $('#eventToggle').removeClass('active');
            $('#agenda').fadeOut();
        } else {
            $('#eventToggle').addClass('active');
            $('#agenda').fadeIn();
        }

        // $('#agenda').fadeToggle(function () {
        //
        // });
        //$(this).find("span").last().text(function (i, text) {
        //    return text === "Show Events" ? "Hide Events" : "Show Events";
        //});
    });

    // Set the fraction-colors of authors
    setFractionColorsOfAuthors();

    parameters = {
        provider_group_container: $("#provider_group_container").val(),
        q: (($("input[name='q']").last().val() == '') ? $("input[name='q']").first().val() : $("input[name='q']").last().val()),
        _token: $("#token").val(),
        date_start: window.date_start,
        date_end: window.date_end,
        dashboardId: window.dashboardId
    };


    $(document).on('click', '#matchGraph', function (e) {

	    var authors = new Array();
		$("input[name='authors[]']:checked").each(function(i) {
		    authors.push($(this).val());
		});

	    var bills = new Array();
		$("input[name='bills[]']:checked").each(function(i) {
		    bills.push($(this).val());
		});

		console.log(bills);

	    loadGraph({provider_group_container: $("#provider_group_container").val(), q: $("input[name='q']").last().val(), _token: $("#token").val(), date_start: window.date_start, date_end: window.date_end, cat_name: getUrlParameter('cat_name'), cat_val: getUrlParameter('cat_val'), authors: authors, bills: bills });

        e.preventDefault();
        $('#matchGraph').prop('disabled', true);
        $('#graphContainer').fadeToggle(function () {
            $('#matchGraph').prop('disabled', false);
        });

    });

	if (!$("#dossier_all").is(':checked') && !$("#dossier_active").is(':checked') && $("#search_field").val() == '') {
		//$("#matchGraph").click();
	}

});

function loadGraph(parameters) {
    $('.ajax-loader-container').fadeIn('100');

    $.ajax({
        url: '/6_weeks_match_count',
        type: 'POST',
        dataType: 'html',
        data: parameters
    }).done( function (data) {
        data = JSON.parse(data);

        var graphData = [
            {
                x: data[0],
                y: data[1],
                type: 'scatter',
                marker: {
                  color: '#28a7e9'
                }
            }
        ];

        if($(".active.dashboard a").html() == undefined) {
            var title_graph = 'Trend Graph';
        } else {
            var title_graph = 'Trend Graph for ' + $(".active.dashboard a").html();
        }

        var layout = {
            title:title_graph,
            margin: {
                autoexpand: false,
                t: 40,
                b: 30
            },
        };

        // Plotly.newPlot('graph', graphData, layout);

        $('.ajax-loader-container').fadeOut('100');


    });
}

function generateAlertPopover() {

    var alertPopoverSettings = {
        placement: 'left',
        html: true,
        content: function () {
            return $(this).siblings('.alert_content').html();
        },
        title: function () {
            return $(this).siblings('.alert_title').html();
        }
    };
    $(".set_alert").popover(alertPopoverSettings).click(function () {
        $("#alert-provider-filter").multiselect({
            enableClickableOptGroups: true,
            enableCollapsibleOptGroups: true,
            enableFiltering: true,
            includeSelectAllOption: true,
            nonSelectedText: "All Sources",
            selectAllText: "All Sources",
            numberDisplayed: 1,
            maxHeight: 300,
            enableCaseInsensitiveFiltering: true,
            selectAllJustVisible: false,
            enableHTML: true,
            buttonContainer: '<div id="example-enableCollapsibleOptGroups-collapsed-container" />',
            onDropdownShown: function () {
                $('#example-enableCollapsibleOptGroups-collapsed-container .caret-container').click();
            },
            onDropdownHidden: function () {
                $('#example-enableCollapsibleOptGroups-collapsed-container .caret-container').click();
            }
        });
    });
}

function generateInfoBox() {
	/*var infoBoxSettings = {
        placement: 'bottom',
        html: true,
        content: function () {
            return '<div style="width: 350px;"><b>Exact Match:</b> "Renewable Energy Directive"<br /><b>OR Search:</b> Renewables OR CO2<br /><b>Exclude Words:</b> Renewables -Germany -CO2<br /><b>Context Search:</b> "Renewables Germany"~20<br /><b>Approximate Search:</b> Renew*</div>';
        },
        title: function () {
            return 'Expert searches';
        }
    };
    $(".info_box_show").popover(infoBoxSettings).click(function () {
        $("#alert-provider-filter").multiselect({
            enableClickableOptGroups: true,
            enableCollapsibleOptGroups: true,
            enableFiltering: true,
            includeSelectAllOption: true,
            nonSelectedText: "All Sources",
            selectAllText: "All Sources",
            numberDisplayed: 1,
            maxHeight: 300,
            enableCaseInsensitiveFiltering: true,
            selectAllJustVisible: false,
            enableHTML: true
        });
    });*/

    $(".info_box_show").popover({
        template: '<div class="popover" role="tooltip" style=""><div class="arrow"></div><div class="popover-content"><div class="data-content"></div></div></div>'
    });
}

function loadDocTypeIcons() {
    if ($("input[type=checkbox][value=regulation]").parent().contents().length == 2) {
        $("input[type=checkbox][value=regulation]").parent().append('<span class="icon-LayoutIcons07-06"></span>');
        $("input[type=checkbox][value=event]").parent().append('<span class="icon-LayoutIcons07-05"></span>');
        $("input[type=checkbox][value=qanda]").parent().append('<span class="icon-LayoutIcons07-02"></span>');
        $("input[type=checkbox][value=short_message]").parent().append('<span class="icon-LayoutIcons07-03"></span>');
        $("input[type=checkbox][value=speech]").parent().append('<span class="icon-LayoutIcons07-18"></span>');
        $("input[type=checkbox][value=publication]").parent().append('<span class="icon-LayoutIcons07-17"></span>');
        $("input[type=checkbox][value=information]").parent().append('<span class="icon-LayoutIcons07-09"></span>');
        $("input[type=checkbox][value=press_release]").parent().append('<span class="icon-LayoutIcons07-09"></span>');
        $("input[type=checkbox][value=minutes]").parent().append('<span class="icon-LayoutIcons07-13"></span>');
    }
}

$('body').on('click', function (e) {

    if (!$(e.target).hasClass("set_alert")
        && $('.set_alert').attr("aria-describedby") !== undefined
        && $(e.target).parents('.set_alert').length === 0
        && $(e.target).parents('.popover.in').length === 0) {
        $('.set_alert').trigger("click");
    }

    if (!$(e.target).hasClass("dropdown-toggle")
        && $('.dropdown.mega-dropdown.open').length > 0
        && $(e.target).parents('.dropdown.mega-dropdown').length === 0) {
        $('a.dropdown-toggle').trigger("click");
    }

});

$(".btn-popover").popover({
    html: true,
    content: function () {
        return $("." + $(this).data('subject')).html();
    },
    title: function () {
        return $("." + $(this).data('title')).html();
    }
});
function getValues(parameters) {
    var values = {};
    $.each(parameters, function (key, value) {
        if (value !== parseInt(value, 10) && typeof value.is == "function") {

            if (value.is('ul')) {
                var selected = [];
                var checked = value.find('input[type=checkbox]:checked');
                if (checked.length > 0) {
                    checked.each(function (index, element) {
                        selected.push($(this).val());

                    });
                    values[key] = selected;
                }
            }
            if (value.is('select')) {

                var selected = [];
                if ($(this).prop('multiple')) {
                    value.find(":selected").each(function (index, element) {
                        selected.push($(this).val());
                    });
                } else {
                    selected = $(this).val();
                }
                values[key] = selected;
//                if(value.find(":selected").val() != undefined && value.find(":selected").val() != "") {
//                    var arr = value.map(function(){
//                        return this.value;
//                    }).get();
//                    values[key] = arr;
//                }
            } else if (value.is('input[type=checkbox]') && value.is(':checked')) {

                if (value.length >= 2) {
                    console.log(value);
                    var arrayValues = [];
                    for (var i = 0, n = value.length; i < n; i++) {
                        if (value[i].checked) {
                            arrayValues.push(value[i].value);
                        }
                    }
                    values[key] = arrayValues;
                } else {
                    values[key] = value.val();
                }
            } else if (value.is('input[type=hidden]')) {
                values[key] = value.val();
            } else if (value.find("input").is('input[type=checkbox]') && value.find("input").is(':checked')) {
                var inside_value = value.find("input");
                if (value.find("input").length >= 2) {
                    var arrayValues = [];
                    for (var i = 0, n = inside_value.length; i < n; i++) {
                        if (inside_value[i].checked) {
                            arrayValues.push(inside_value[i].value);
                        }
                    }
                    values[key] = arrayValues;
                } else {
                    values[key] = inside_value.val();
                }
            } else if (value.is("input[type=text]")) {
                values[key] = value.val();
            } else if (value.is("input[type=radio]")) {
                if (value.is(':checked')) {
                    values[key] = value.val();
                }

            }
        }

        if ($("#search_cat_name").val() != '') {
	        values['cat_name'] = $("#search_cat_name").val();
        }

        if ($("#search_cat_val").val() != '') {
	        values['cat_val'] = $("#search_cat_val").val();
        }

        if (values['q'] == '') {
	        values['q'] = (($("input[name='q']").last().val() == '') ? $("input[name='q']").first().val() : $("input[name='q']").last().val());
        }
    });

    //removed from loop
    if (window.date_start && window.date_end) {
        values['date_start'] = window.date_start;
        values['date_end'] = window.date_end;
    }
    return values;
}

function disableField(element, disabled) {
    if ($.isArray(element)) {
        $.each(element, function (key, value) {
            disableField(value, disabled);
        });
    } else {
        $(".form-group." + element).find("input, select").prop('disabled', disabled);
        if (disabled == true) {
            $(".form-group." + element).hide();
        } else {
            $(".form-group." + element).show();
        }
    }
}

function calculateLeadType(leadType, role, employee) {
    role = parseInt(role);
    employee = parseInt(employee);
    switch (role) {
        case 1:
            leadType.val('big_fish');
            break;
        case 2:
            if (employee >= 4) {
                leadType.val('big_fish');
            } else {
                leadType.val('fish');
            }
            break;
        case 4:
            if (employee >= 2) {
                leadType.val('competitor');
            } else {
                leadType.val('partner');
            }
            break;
        case 5:
            if (employee >= 2) {
                leadType.val('competitor');
            } else {
                leadType.val('partner');
            }
            break;
        case 6:
            leadType.val('embessedor');
            break;
        case 7:
            leadType.val('embessedor');
            break;
        case 8:
            leadType.val('agent');
            break;
    }
    return leadType;
}

function initFacetExpand() {
    $(".facet-holder").readmore({
        collapsedHeight: 95,
        moreLink: '<a href="#">See more</a>'
    });

}

function initTabs() {



    var tabInit = function (e) {
        var $this = $(this),
            targ = $this.attr('href');
        var filters = {};
        $(".hidden_page").val(1);

        $(document).find("[data-search]").each(function () {
            filters[$(this).data('search')] = $(this);
        });

        $("#provider_group_container").val(targ.substring(1));
        getDocuments($(targ), filters, false);

    };

}
function initFilters() {
    $('#date_from').datepicker();
    $('#date_to').datepicker({
        useCurrent: false //Important! See issue #1075
    });
    $("#date_from").on("change.dp", function (e) {
        $("#min_date").attr('value', $.datepicker.formatDate("mm/dd/yy", new Date($(this).datepicker('getDate'))));
        $(this).datepicker("hide");
        // $('#date_to').data("DateTimePicker").minDate(e.date);
    });
    $("#date_to").on("change.dp", function (e) {
        $("#max_date").attr('value', $.datepicker.formatDate("mm/dd/yy", new Date($(this).datepicker('getDate'))));
        $(this).datepicker("hide");
        // $('#date_from').data("DateTimePicker").maxDate(e.date);
    });
    $("#doc_types-filter").multiselect({
        includeSelectAllOption: true,
        nonSelectedText: "Doc types",
        allSelectedText: "All doc types",
        selectAllText: "All doc types",
        selectAllValue: "",
        numberDisplayed: 1,
        nSelectedText: "Doc types",
        selectAllNumber: false,
        onChange: function(){
	        window.filter_changed = true;
        },
        onSelectAll: function(){
	        window.filter_changed = true;
	    },
	    onDeselectAll: function(){
		    window.filter_changed = true;
	    },
        onDropdownHidden: function(){
	        getDocuments(window.documents_filter, window.filters_filter, true, window.id_filter);
        }
    });
    $("#provider-filter").multiselect({
        includeSelectAllOption: true,
        nonSelectedText: "Parliaments",
        allSelectedText: "All parliaments",
        selectAllText: "All parliaments",
        selectAllValue: "",
        numberDisplayed: 1,
        nSelectedText: "Parliaments",
        selectAllNumber: false,
        onChange: function(){
	        window.filter_changed = true;
        },
        onSelectAll: function(){
	        window.filter_changed = true;
	    },
	    onDeselectAll: function(){
		    window.filter_changed = true;
	    },
        onDropdownHidden: function(){
	        getDocuments(window.documents_filter, window.filters_filter, true, window.id_filter);
        }
    });
    $("#filter-btn").click(function (event) {
        event.preventDefault();
        $("#filter-bar").slideToggle('slow');
    });

}

window.date_now = Date.now();
function getAgenda() {
    parameters.dashboardId = window.dashboardId;
    $.ajax({
        url: '/get-nd-events',
        type: 'POST',
        data: parameters,
        cache: false
    }).done(
        function (data) {
            $('#agendaContainer').html(data.view);
            $('#eventToggle').removeClass('hidden');
        });
}

function getSHRanking() {
    var parameters = window.parameters_save;
    $.ajax({
        url: 'get-nd-sh-ranking',
        type: 'POST',
        dataType: 'html',
        data: parameters,
        cache: false
    }).done(
        function (data) {
            $('.stakeholder-facet-controls').html(data);
        }
    );
}

function getPopUpData(data) {
    showLoader('#trend-results-modal-body', 30);
    let resultText = data.value > 1 ? window.trans('modals.trend.results','results') : window.trans('modals.trend.result','Result');
    $('#trend-results-modal-title').html("<div style='display: inline-block; position: absolute; left: 15px; margin-top: 1px;'>" + data.term + "</div><span id='trend-results-count'>" + data.value + " " + resultText + " </span>");
    $('#trend-results-modal').modal('show');
    if (data.party_id !== undefined) {
        localStorage.selectedParty = data.party_id
    }
    if (data.tag !== undefined) {
        localStorage.selectedTag = data.tag
    } else {
        delete localStorage.selectedTag;
    }
    //@todo global variables from trend-graph.blade.php
    graphSelectedDate = data.start_date;
    graphSelectedDateEnd = data.end_date;
    getFilteredDocs(graphSelectedDate, graphSelectedDateEnd);
}

function getWordcloud() {
    var parameters = window.parameters_save;
    $.ajax({
        url: 'get-nd-wordcloud',
        type: 'POST',
        dataType: 'json',
        data: parameters,
        cache: false
    }).done(
        function (data) {
            if (data && data.length > 0) {
                WordCloudChart.init('nd-word-cloud', data, getPopUpData);
            } else {
                $('#nd-word-cloud').html('');
            }
        }
    );
}

function getFacets() {
    var parameters = window.parameters_save;
    $.ajax({
        url: 'get-nd-facets',
        type: 'POST',
        dataType: 'json',
        data: parameters,
        cache: false
    }).done(
        function (data) {
            var parameters = window.parameters_save;
            var providersString = JSON.stringify(data.provider_facets);
            var selectedProvidersString = JSON.stringify(parameters.providers);
            NavigationBarSubmenu.create(
                'providers',
                providersString,
                selectedProvidersString
            );

            var stakeholdersString = JSON.stringify(data.stakeholder_facets);
            var selectedStakeholdersString = JSON.stringify(parameters.stakeholders);
            NavigationBarSubmenu.create(
                'stakeholders',
                stakeholdersString,
                selectedStakeholdersString
            );

            var authorsString = JSON.stringify(data.authors);
            var selectedAuthorsString = JSON.stringify(parameters.authors);
            NavigationBarSubmenu.create(
                'authors',
                authorsString,
                selectedAuthorsString
            );

            var socialAccountsString = JSON.stringify(data.socialAccounts);
            var selectedSocialAccountsString = JSON.stringify(parameters.socialAccounts);
            NavigationBarSubmenu.create(
                'socialAccounts',
                socialAccountsString,
                selectedSocialAccountsString
            );

            var hashtagsString = JSON.stringify(data.hashtags);
            var selectedHashtagsString = JSON.stringify(parameters.hashtags);
            NavigationBarSubmenu.create(
                'hashtags',
                hashtagsString,
                selectedHashtagsString
            );

            var billsString = JSON.stringify(data.bills);
            var selectedBillsString = JSON.stringify(parameters.bills);
            NavigationBarSubmenu.create(
                'bills',
                billsString,
                selectedBillsString
            );

            var recordNumbersString = JSON.stringify(data.record_numbers);
            var selectedRecordNumbersString = JSON.stringify(parameters.record_numbers);
            NavigationBarSubmenu.create(
                'record_numbers',
                recordNumbersString,
                selectedRecordNumbersString
            );

            var docTypesString = JSON.stringify(data.doc_types);
            var selectedDocTypesString = JSON.stringify(parameters.doc_types);
            NavigationBarSubmenu.create(
                'doc_types',
                docTypesString,
                selectedDocTypesString
            );

            NavigationBar.maxHeight();
            FilterRules.checkVisibility();
        }
    );
}

function getDocuments(documents, filter_values, initTabsFlag, facet) {
    var searchFieldValue = $("input#search_field.form-control.search_field.search_suggestions.global-search").val();


    //if (window.date_now < Date.now() - 1000) {
        window.date_now = Date.now();
        if (!window.show_per_page) {
            window.show_per_page = 10;
        }

        var parameters = getValues(filter_values);

        parameters['show_per_page'] =  window.show_per_page;
        parameters['_token'] = $("input[name='_token']").val();
        parameters['charts'] = localStorage.charts ?? false;
        parameters['search_field_value'] = searchFieldValue != null ? searchFieldValue : null;

        if (localStorage.charts) {
            if (localStorage.charts !== 'false' && window.parliament == 'undefined') {
                parameters['parliament'] = 1;
                console.log('setting parliament to 1')
            } else if (window.parliament != null) {
                parameters['parliament'] = window.parliament;
                console.log('setting parliament to ' + window.parliament)
            }
        }

		window.globalParameters = parameters;

        if (window.cleanFacets == true) {

			parameters['authors'] = '';
			parameters['bills'] = '';
			parameters['socialAccounts'] = '';
			parameters['hashtags'] = '';
			parameters['record_numbers'] = '';
			parameters['providers'] = '';

			window.cleanFacets = false;
		}

		// console.log('params', parameters);

        $(".ajax-modal").fadeIn('100');
        Helper.showFacetLoader('.filter .submenu:not(.nav-dossiers-submenu,.nav-events-submenu,.nav-region-submenu,.nav-language-submenu)');

        var html = $("#" + facet).html();

        if (parameters.provider_group_container != 'legislative') {
	        if (typeof parameters.provider !== undefined && parameters.provider != null) {
		        window.providers_remember = parameters.provider;
	        }

			parameters.provider = null;
		} else {
			if (typeof window.providers_remember !== undefined && window.providers_remember != '') {
				parameters.provider = window.providers_remember;
				window.providers_remember = '';
			} else {
				window.providers_remember = parameters.provider;
			}
		}

        var dossier_checked = urlParam('dossiers[]');

        if (dossier_checked) {
            var params = urlParam();
            var newUrl = window.location.href.split("?")[0];
            var query = Object.keys(params).filter(function(key) {
                return key !== 'dossiers[]';
            }).map(function(key) {
                return key + '=' + params[key];
            }).join('&');
            newUrl += '?' + query;
            window.history.pushState({}, document.title, newUrl);
            $('#dossier_' + dossier_checked).attr('checked', true);
            parameters.dossiers = [dossier_checked];
            parameters.sort = 'dossier_last_modified';
            appendDossierLastModifiedOptionToSort();
            parameters.date_start = window.date_start;
            parameters.date_end = window.date_end;
        }

		parameters.dashboardId = window.dashboardId;

        $.ajax({
            url: documents.data('url'),
            type: 'POST',
            dataType: 'html',
            data: parameters,
			cache: false,
            beforeSend: function(){
                //alert('moving');
                window.parameters_save = parameters;

				//if (typeof $("#reportrange_search").html() != 'undefined') {
					//window.reportRange = $("#period-filter-box").html();

				//}

            }
        }).done(
            function (data) {
                $("#reportrange_search").prependTo('#date-box-holder');

                documents.html(data);
                if (localStorage.charts != 'false' && localStorage.charts !== undefined) {
                    $('#chartToggle').addClass('active')
                    NavigationBar.hideSidebar();
                } else {
                    $('#ndToggle').addClass('active')
                }
                $(".ajax-modal").fadeOut('300');
                if(
                    ($("#search_field").val() != '' && window.date_start != 'Thu Jan 01 1970 00:00:00 GMT+0100')
                    ||
                    (window.date_start == moment().subtract(29, 'days').format('MMMM D, Y') &&
                        window.date_end == moment().format('MMMM D, Y'))
                ){

                    if($(data).find('.too-few').length > 0 && $('.nav-events-submenu input:checked').length == 0 && (!window.facets_names || window.facets_names.length == 0)){
                        window.date_start = 'Thu Jan 01 1970 00:00:00 GMT+0100';
                        var entireTime = window.trans('new_search.dates.entire_time','Entire Time');
                        $('#reportrange_search').addClass('auto-min-width');
                        $(".ajax-modal").fadeIn('100');
                        $('.ranges li[data-range-key="'+entireTime+'"]').click();
                        $('.daterangepicker .applyBtn').prop('disabled', false).click();
                        $("#reportrange_search span").html(entireTime);
                    }
                } else if($("#search_field").val() != '' && window.date_start == 'Thu Jan 01 1970 00:00:00 GMT+0100'){
                    $('#reportrange_search').addClass('auto-min-width');
                    $("#reportrange_search span").html(window.trans('new_search.dates.entire_time','Entire Time'));
                }

                $('#sort').selectize({
                    allowEmptyOption: true,
                    create: true
                });

                if (!$("#dossier_all").is(':checked') && !$("#dossier_active").is(':checked') && $("#search_field").val() == '') {
					//$("#matchGraph").click();
				}

                //If the user is in the search page, show the stakeholder resonance
                if (window.location.href.indexOf('search') > -1 && (window.resonanceShStatus === undefined || window.resonanceShStatus == 1)) {
                    showStakeholderResonance();
                }

                try {
                    unauthorized();
                }
                catch (err) {

                }
    //            $checked  = $("#"+facet).find('input[type=checkbox]:checked');
                if (facet != "startdate" && facet != "sort") {//sorting and period filters need to change
                    $("#" + facet).html(html);
    //                $checked.each(function(){
    //                    $("#"+facet).find('input[data-value="'+$(this).val()+'"]').prop("checked",true);
    //                });
                }


                $(".select2-tag-popover").select2({
                    tags: true,
                    tokenSeparators: [',']
                });
                initFilters();//initiate the filters as they are rendered

                if (initTabsFlag) {
                    initTabs();//initiate the tabs as they are rendered
                }
                generateAlertPopover();
                generateInfoBox();

                $(".bill").readmore({
                    //collapsedHeight: 35,
                    collapsedHeight: 25,
                    /*moreLink: '<a href="#">See more</a>'*/
                    moreLink: '<div><a class="pull-right" href="#">See more</a></div>',
                    lessLink: '<div><a class="pull-right" href="#">Close</a></div>'
                });
                $(".author_list").readmore({
                    collapsedHeight: 18,
                    /*moreLink: '<a href="#">See more</a>'*/
                    moreLink: '<div><a class="pull-right" href="#">See more</a></div>',
                    lessLink: '<div><a class="pull-right" href="#">Close</a></div>'
                });
                loadDocTypeIcons();
                $(".location-icon").tooltip({
                    placement: "right"
                });

                // Set the fraction-colors of authors
                // FilterRules.checkVisibility();
                setFractionColorsOfAuthors();
                var providerGroupContainer = $("#provider_group_container").val();
                if (providerGroupContainer == 'legislative') {
                    $("#parliamentButtonWrapper").show();
                    setTimeout(function () {
                        $('.panel-bills, .panel-record_numbers, .panel-authors, .panel-providers').fadeIn();
                        initFacetExpand();
                    }, 1000);
                } else if (providerGroupContainer == 'news') {
                    $('.panel-record_numbers').hide();
                    $("#parliamentButtonWrapper").hide();
                    setTimeout(function () {
                        $('.panel-bills, .panel-authors, .panel-providers, .panel-providers').fadeIn();

                        initFacetExpand();
                    }, 1000);
                } else if (providerGroupContainer == 'all' || providerGroupContainer == 'administration' || providerGroupContainer == 'forum' || providerGroupContainer == 'courts') {
                    $('.panel-record_numbers').hide();
                    $("#parliamentButtonWrapper").hide();
                    setTimeout(function () {
                        $('.panel-providers').fadeIn();

                        initFacetExpand();
                    }, 1000);
                } else {
                    $("#parliamentButtonWrapper").hide();
                    setTimeout(function () {
                        $('.panel-bills, .panel-record_numbers, .panel-authors').hide();
                    }, 100);
                }

                if (providerGroupContainer == 'social_media') {
                    setTimeout(function () {
                        $('.panel-socialAccounts').fadeIn();
                        initFacetExpand();
                    }, 1000);
                } else {
                    setTimeout(function () {
                        $('.panel-socialAccounts').hide();
                    }, 100);
                }

                $("#reportrange_search").prependTo('#period-filter-box');

                if (localStorage.charts != 'true' || localStorage.charts == undefined) {
                    if (documents.data('events') == true) {
                        getAgenda();
                    }
                    getFacets();
                } else {
                    getWordcloud();
                    getSHRanking();
                }


            }

        ).always(function (data) {
            
            // console.log((($("input[name='q']").last().val() == '') ? $("input[name='q']").first().val() : $("input[name='q']").last().val()));
            parameters = {
                provider_group_container: $("#provider_group_container").val(),
                q: (($("input[name='q']").last().val() == '') ? $("input[name='q']").first().val() : $("input[name='q']").last().val()),
                date_start: window.date_start,
                date_end: window.date_end,
                _token: $("#token").val(),
                cat_val: $("#search_cat_val").val(),
                cat_name: $("#search_cat_name").val(),
                dashboardId: window.dashboardId
            };

            //loadGraph(parameters);
        }).error(function (data) {
            $.notify({
                // options
                message: data.responseText
            }, {
                // settings
                type: 'danger'
            });
        });
    //}
}

function updateValue(value, field, type) {
    type = type || 'text';
    if (type == 'select') {
        if (value != '' && value != null) {
            $("." + field).val(value);
            $("." + field).find("option[value=" + value + "]").prop("selected", true);
        } else {
            $("." + field).find("option:first").prop("selected", true);
        }
    } else {
        if (value != '' && value != null) {
            $("." + field).val(value);
        } else {
            $("." + field).val($("." + field).data(field));
        }
    }
}


/**
 * Get the fractions of all authors and set the colors according to them
 */
function setFractionColorsOfAuthors() {

    //

    var authorIDs = new Array;


    $(".glyphicon-user").each(function () {
        var id = $(this).attr("href");
        id = id.match(/[0-9]{1,5}/g)[0];
        authorIDs.push(id);
    });

    if ($(".glyphicon-user").length) {
        $.ajax({
            url: '/fractionofauthors',
            data: {
                '_token': $("input[name='_token']").val(),
                'authorIDs': JSON.stringify(authorIDs)
            },
            method: 'post',
            success: function (returnvalue) {

                returnvalue = decodeUnicode(returnvalue);

                // Characters that have to be replaced..
                var replacedChars = ['ä', 'ö', 'ü', 'Ä', 'Ö', 'Ü', '/', ' ', '\\\\', '&'];
                var charArray = ['ae', 'oe', 'ue', 'AE', 'OE', 'UE', '', '', '', ''];
                for (var i2 = 0; i2 < replacedChars.length; i2++) {
                    var regExp = new RegExp(replacedChars[i2], 'g');
                    returnvalue = returnvalue.replace(regExp, charArray[i2]);
                }

                returnvalue = JSON.parse(returnvalue);

                $(".glyphicon-user").each(function (index) {
                    $(this).addClass(returnvalue[Index]);
                });
            }
        });
    }

    function decodeUnicode(str) {

        var r = /\\u([\d\w]{4})/gi;
        str = str.replace(r, function (match, grp) {
            return String.fromCharCode(parseInt(grp, 16)); } );
        str = decodeURI(str);
        return str;
    }

}

function showSearchInResultsInput(action){
    if(action == 'open') {
        $('#toggle-search-in-results-container #open-search-in-results-btn').fadeToggle('fast', function () {
            $('#toggle-search-in-results-container .input-container').fadeToggle("fast");
        });
    }else{
        $('#toggle-search-in-results-container .input-container').fadeToggle('fast', function () {
            $('#toggle-search-in-results-container #open-search-in-results-btn').fadeToggle("fast");

        });
    }

}

